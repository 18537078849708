<template>
   <div class="relative">
      <div class="categories-container" v-if="!minWidth1367">
         <div
            class="category category-slots-bar bg-pattern-blue flex items-center w-full"
            style="justify-content: center !important"
            :class="{
               'category-highlighted': isCategoryHighlighted('slots-bar'),
               'category-not-highlighted':
                  isCategoryNotHighlighted('slots-bar'),
            }"
            @mouseover="categoryHighlighted = 'slots-bar'"
            @mouseleave="reset"
         >
            <div
               class="flex flex-col md:flex-row lg:flex-col xl:flex-row md:gap-x-24 lg:gap-0 items-center justify-center"
            >
               <a
                  href="https://beta-vr.casinomania.it/"
                  target="_blank"
               >
                  <div class="category-icon flex-1">
                     <custom-image
                        original="/img/home/categories/cabinets.png"
                     />
                     <h2>Slot Bar<br />& VLT</h2>
                  </div>

                  <div
                     class="sub-category flex-1 flex items-center justify-center"
                     v-if="isCategoryHighlighted('slots-bar') && showButton"
                  >
                     <button
                        class="my-4 md:my-0 lg:mt-2 lg:ml-4 w-56 lg:text-xl"
                     >
                        Gioca Ora
                     </button>

                     <!-- <router-link :to="{name: 'SlotsVlt'}">
            <div class="category-icon">
              <custom-image original="/img/home/categories/cabinets_vlt.png" />
              <h4>VLT</h4>
            </div>
            <button>Gioca Ora</button>
          </router-link> -->
                  </div>
               </a>
            </div>
         </div>
         <div
            class="flex flex-col w-full md:flex-row md:flex-wrap lg:flex-1 lg:flex-nowrap lg:h-full"
         >
            <router-link
               :to="{ name: 'Bets' }"
               class="category category-betting bg-pattern-cyan"
               :class="{
                  'category-highlighted': isCategoryHighlighted('betting'),
                  'category-not-highlighted':
                     isCategoryNotHighlighted('betting'),
               }"
               @mouseover.native="categoryHighlighted = 'betting'"
               @mouseleave.native="reset"
            >
               <div class="category-icon">
                  <custom-image original="/img/home/categories/betting.png" />
                  <h2>Scommesse<br />Sportive</h2>
               </div>

               <button
                  v-if="
                     isCategoryHighlighted('betting') &&
                     showButton &&
                     minWidth1367
                  "
               >
                  Gioca Ora
               </button>
            </router-link>
            <router-link
               :to="{ name: 'CasinoLive' }"
               class="category category-live-casino bg-pattern-yellow"
               :class="{
                  'category-highlighted': isCategoryHighlighted('live-casino'),
                  'category-not-highlighted':
                     isCategoryNotHighlighted('live-casino'),
               }"
               @mouseover.native="categoryHighlighted = 'live-casino'"
               @mouseleave.native="reset"
            >
               <div class="category-icon">
                  <custom-image
                     original="/img/home/categories/live-casino.png"
                  />
                  <h2>Casino<br />Live</h2>
               </div>

               <button
                  v-if="
                     isCategoryHighlighted('live-casino') &&
                     showButton &&
                     minWidth1367
                  "
               >
                  Gioca Ora
               </button>
            </router-link>
            <router-link
               :to="{ name: 'OnlineGaming' }"
               class="category category-online-gaming bg-pattern-orange"
               :class="{
                  'category-highlighted':
                     isCategoryHighlighted('online-gaming'),
                  'category-not-highlighted':
                     isCategoryNotHighlighted('online-gaming'),
               }"
               @mouseover.native="categoryHighlighted = 'online-gaming'"
               @mouseleave.native="reset"
            >
               <div class="category-icon">
                  <custom-image
                     original="/img/home/categories/online-gaming.png"
                  />
                  <h2>Giochi<br />Online</h2>
               </div>

               <button
                  v-if="
                     isCategoryHighlighted('online-gaming') &&
                     showButton &&
                     minWidth1367
                  "
               >
                  Gioca Ora
               </button>
            </router-link>
            <router-link
               :to="{ name: 'Lottery' }"
               class="category category-lottery bg-pattern-red"
               :class="{
                  'category-highlighted': isCategoryHighlighted('lottery'),
                  'category-not-highlighted':
                     isCategoryNotHighlighted('lottery'),
               }"
               @mouseover.native="categoryHighlighted = 'lottery'"
               @mouseleave.native="reset"
            >
               <div class="category-icon">
                  <custom-image original="/img/home/categories/lottery.png" />
                  <h2>Lotteria</h2>
               </div>

               <button
                  v-if="
                     isCategoryHighlighted('lottery') &&
                     showButton &&
                     minWidth1367
                  "
               >
                  Gioca Ora
               </button>
            </router-link>
         </div>
      </div>
      <div class="categories-container" v-else>
      <div class="category category-slots-bar bg-pattern-blue"
           :class="{'category-highlighted': isCategoryHighlighted('slots-bar'), 'category-not-highlighted': isCategoryNotHighlighted('slots-bar')}"
           @mouseover="categoryHighlighted = 'slots-bar'" @mouseleave="reset">
           
        <div class="category-icon flex-1">
          <custom-image original="/img/home/categories/cabinets.png" classList="max-img"/>
          <h2>Slot Bar<br/>& VLT</h2>
        </div>

        <div class="sub-category"  v-if="isCategoryHighlighted('slots-bar') && showButton">
          <a href="https://beta-vr.casinomania.it">
            <button class="ml-10 w-52" style="font-size: 24px; padding-top: 0.75rem !important; padding-bottom: 0.75rem !important;">Gioca Ora</button>
          </a>
        </div>
      </div>
      <router-link :to="{name: 'Bets'}" class="category category-betting bg-pattern-cyan" style="height: 100%;"
                   :class="{'category-highlighted': isCategoryHighlighted('betting'), 'category-not-highlighted': isCategoryNotHighlighted('betting')}"
                   @mouseover.native="categoryHighlighted = 'betting'" @mouseleave.native="reset">
        <div class="category-icon">
          <custom-image original="/img/home/categories/betting.png" />
          <h2>Scommesse<br/>Sportive</h2>
        </div>

        <button v-if="isCategoryHighlighted('betting') && showButton">Gioca Ora</button>
      </router-link>
      <router-link :to="{name: 'CasinoLive'}" class="category category-live-casino bg-pattern-yellow"  style="height: 100%;"
                   :class="{'category-highlighted': isCategoryHighlighted('live-casino'), 'category-not-highlighted': isCategoryNotHighlighted('live-casino')}"
                   @mouseover.native="categoryHighlighted = 'live-casino'" @mouseleave.native="reset">
        <div class="category-icon">
          <custom-image original="/img/home/categories/live-casino.png" />
          <h2>Casino<br/>Live</h2>
        </div>

        <button v-if="isCategoryHighlighted('live-casino') && showButton">Gioca Ora</button>
      </router-link>
      <router-link :to="{name: 'OnlineGaming'}" class="category category-online-gaming bg-pattern-orange"  style="height: 100%;"
                   :class="{'category-highlighted': isCategoryHighlighted('online-gaming'), 'category-not-highlighted': isCategoryNotHighlighted('online-gaming')}"
                   @mouseover.native="categoryHighlighted = 'online-gaming'" @mouseleave.native="reset">
        <div class="category-icon">
          <custom-image original="/img/home/categories/online-gaming.png" />
          <h2>Giochi<br/>Online</h2>
        </div>

        <button v-if="isCategoryHighlighted('online-gaming') && showButton">Gioca Ora</button>
      </router-link>
      <router-link :to="{name: 'Lottery'}" class="category category-lottery bg-pattern-red"  style="height: 100%;"
                   :class="{'category-highlighted': isCategoryHighlighted('lottery'), 'category-not-highlighted': isCategoryNotHighlighted('lottery')}"
                   @mouseover.native="categoryHighlighted = 'lottery'" @mouseleave.native="reset">
        <div class="category-icon">
          <custom-image original="/img/home/categories/lottery.png" />
          <h2>Lotteria</h2>
        </div>

        <button v-if="isCategoryHighlighted('lottery') && showButton">Gioca Ora</button>
      </router-link>
    </div>

   </div>
</template>

<script>
export default {
   name: "MainCategorySelectionMenu",

   data() {
      return {
         categoryHighlighted: "slots-bar",
         showButton: true,
         minWidth1367: window.innerWidth >= 1367,
      };
   },

   watch: {
      categoryHighlighted(newVal) {
         this.showButton = false;
         if (newVal !== "") {
            setTimeout(() => {
               this.showButton = true;
            }, 500);
         }
      },
   },

   mounted() {
      window.addEventListener("resize", this.updateminWidth1367);
   },

   methods: {
      isCategoryHighlighted(category) {
         return this.categoryHighlighted === category;
      },
      isCategoryNotHighlighted(category) {
         return (
            this.categoryHighlighted !== "" &&
            this.categoryHighlighted !== category
         );
      },
      reset() {
         this.categoryHighlighted = "slots-bar";
      },
      updateminWidth1367() {
         this.minWidth1367 = window.innerWidth >= 1367;
      },
   },
};
</script>

<style lang="postcss">
.categories-container {
   @apply w-full flex flex-nowrap flex-col items-start;

   @screen md {
      @apply flex-wrap;
   }

   @screen lg {
      width: 104%;
      height: 350px;
      transform: skewX(-5.5deg);
      @apply flex-row flex-nowrap;
   }

   .category {
      flex: 1 1 20%;
      transition: flex 500ms ease-in-out;
      @apply w-full h-full border-r-2 border-gray-500 flex items-center justify-center lg:justify-between px-10 py-10 xl:px-16 xl:py-8 cursor-pointer;

      @screen md {
         height: auto;
         flex: 1 1 50%;
      }

      @screen lg {
         &.category-highlighted {
            flex: 1 1 30%;
         }

         &.category-not-highlighted {
            flex: 1 1 10%;
         }

         &:first-child {
            @apply -ml-10 h-full;
         }

         &:last-child {
            @apply pr-20 xl:pr-32;
         }
      }

      /* @screen md {
         &:first-child {
            flex: 1 1 100%;
         }
      } */

      .sub-category {
         @apply flex flex-col items-start gap-4;

         > a {
            @apply flex flex-row items-center;

            .category-icon {
               @apply gap-0;

               h4 {
                  @apply font-bold uppercase whitespace-normal tracking-wide text-lg text-center;
               }

               img {
                  @apply w-auto h-28;

                  @screen lg {
                     transform: skewX(5.5deg);
                  }
               }
            }

            button {
               @apply uppercase text-lg xl:text-2xl px-8 py-1 xl:py-2 rounded-full shadow-lg cursor-pointer text-black;
            }
         }
      }

      &-slots-bar {
         button {
            @apply bg-secondary;

            &:hover {
               @apply bg-primary;
            }
         }
      }

      &-betting {
         button {
            @apply bg-secondary;

            &:hover {
               @apply bg-primary;
            }
         }
      }

      &-live-casino {
         button {
            @apply bg-primary;

            &:hover {
               @apply bg-secondary;
            }
         }
      }

      &-online-gaming {
         button {
            @apply bg-primary;

            &:hover {
               @apply bg-secondary;
            }
         }
      }

      &-lottery {
         button {
            @apply bg-secondary;

            &:hover {
               @apply bg-primary;
            }
         }
      }

      .category-icon {
         @apply flex flex-col items-center justify-center gap-4;

         h2 {
            @apply font-black uppercase whitespace-normal tracking-wide text-xl xl:text-2xl text-center;
         }

         img {
            @apply w-40;
            @screen lg {
               transform: skewX(5.5deg);
            }
            &.max-img {
               @apply w-52;
            }
         }
      }

      button {
         @apply uppercase text-xl px-12 py-3 rounded-full shadow-xl cursor-pointer text-black;
      }
   }
}

.category-betting,
.category-live-casino,
.category-online-gaming,
.category-lottery {
   @screen md {
      flex: 1 1 50%;
   }
}
</style>
