<template>
  <div>
    <div class="flex flex-col">
      <FullWidthBanner/>

      <MainCategorySelectionMenu></MainCategorySelectionMenu>
    </div>

    <GamesListingSection title="Slot | C6a" url="#" :games="c6aGames" :supportsTouch="supportsTouch"></GamesListingSection>

    <GamesListingSection title="Slot | VLT" url="#" :games="vltGames" :supportsTouch="supportsTouch"></GamesListingSection>
  </div>
</template>

<script>
import GamesListingSection from "@/components/GamesListingSection";
import MainCategorySelectionMenu from "@/components/MainCategorySelectionMenu";
import FullWidthBanner from "@/components/FullWidthBanner";

export default {
  name: 'Home',

  components: {
    FullWidthBanner,
    GamesListingSection,
    MainCategorySelectionMenu
  },

  props: ['supportsTouch'],

  data() {
    return {
      c6aGames: [
        {
          image: '/img/c6a-games/1.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/2.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/3.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/4.png',
          gameUrl: '#'
        },
        {
          image: '/img/c6a-games/5.png',
          gameUrl: '#'
        },
      ],
      vltGames: [
        {
          image: '/img/vlt-games/1.png',
          gameUrl: '#'
        },
        {
          image: '/img/vlt-games/2.png',
          gameUrl: '#'
        },
        {
          image: '/img/vlt-games/3.png',
          gameUrl: '#'
        },
        {
          image: '/img/vlt-games/4.png',
          gameUrl: '#'
        },
        {
          image: '/img/vlt-games/5.png',
          gameUrl: '#'
        },
      ]
    }
  }

}
</script>

